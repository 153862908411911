.loadingContainer {
  animation-name: fadein;
  animation-duration: 0.5s;
}

@keyframes fadein {
  from {
    opacity: 0%;
  }
  50% {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}